/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "~@coreui/icons/css/all.min.css";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #000747 !important;
}
.card {
  color: #ffffff !important;
}
.required {
  color: red;
}

.header-cell {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.small-input {
  // width: 120px;
  font-size: 12px;
  margin-right: 8px;
  padding: auto !important;
}

// .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
//   padding-top: 12px !important;
// }

.header-name {
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
}

.header-name {
  padding: 12px 0 15px 0;
}

.mdc-notched-outline {
  height: 90% !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  .mat-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    display: table;
    border-collapse: collapse;
    margin: 0px;
  }

  .mat-row,
  .mat-header-row {
    display: table-row;
  }

  .mat-cell,
  .mat-header-cell {
    word-wrap: initial;
    display: table-cell;
    padding: 0px 5px;
    line-break: unset;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
}

.hide-data {
  display: none;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0b1674;
  padding: 30px;
}

.custom-icon {
  font-weight: bold;
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
}

.badge-container {
  display: flex;
  align-items: center;
}

.sorting-icon {
  font-size: 18px;
  padding-left: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.nofilter {
  height: 80px !important;
}

.sidebar-nav .nav-icon:first-child {
  margin-left: -51px !important;
}

.sidebar-nav .nav-group-items .nav-link {
  padding-left: 30px !important;
}

.ck-content {
  height: 265px !important;
}
.bg-light {
  background: #000747 !important;
}
.card-group > .card {
  background: #000747 !important;
  border: 1px solid #1736a9 !important;
}
.btn-primary {
  border-radius: 15px !important;
  background-color: #45b1fc !important;
  border: 1px solid #45b1fc !important;
  color: #000747 !important;
}
.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  color: #ffffff !important;
}
form .text-medium-emphasis {
  color: #3340b6 !important;
}
.right-align {
  display: flex;
  justify-content: right;
  width: 100%;
}
.btn-link {
  color: #43adf7 !important;
}
.form-control {
  background: #000747 !important;
  border: 1px solid #1736a9 !important;
  color: #ffffff !important;
  box-shadow: none !important;
  font-size: 14px !important;
}
.input-group-text {
  background: #000747 !important;
  border: 1px solid #1736a9 !important;
  color: #43adf7 !important;
}
.crediantialLogo {
  max-width: 180px;
  margin: 0 auto;
  display: block;
}
.sidebar {
  background: linear-gradient(
    320deg,
    rgba(0, 5, 53, 1) 0%,
    rgba(4, 46, 187, 1) 100%
  ) !important;
}
.header.header-sticky,
.footer {
  background: linear-gradient(
    320deg,
    rgba(0, 5, 53, 1) 0%,
    rgba(4, 46, 187, 1) 100%
  ) !important;
}
.header-toggler {
  color: #ffffff !important;
}
.card {
  border: 1px solid #1736a9 !important;
  background: rgb(54, 97, 254) !important;
  background: linear-gradient(
    326deg,
    rgb(54 97 254 / 50%) 0%,
    rgba(0, 9, 74, 1) 25%,
    rgba(0, 9, 74, 1) 50%,
    rgba(0, 9, 74, 1) 75%,
    rgba(54, 97, 254, 0.5) 100%
  ) !important;
  border-radius: 15px !important;
}
.breadcrumb-item a {
  color: #43adf7 !important;
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #ffffff !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff !important;
}
.sidebar-nav .nav-link {
  color: #ffffff !important;
}
.header-divider,
.header.header-sticky {
  border-color: #0023a2 !important;
}
.card-body .text-medium-emphasis {
  color: #6e8bf5 !important;
}
.card-body .fw-semibold {
  color: #ffffff !important;
}
.card-header {
  color: #fff !important;
}
td {
  color: #fff !important;
}
.table > :not(caption) > * > *,
.border {
  border-color: #1736a9 !important;
}
thead th {
  background-color: #051056 !important;
  color: #ffffff !important;
}
.footer {
  border-color: #1736a9 !important;
}
.footer .text-end {
  color: #ffffff !important;
}
.mdc-data-table__cell,
.mat-mdc-paginator-container {
  background: #000747 !important;
  border-color: #1736a9 !important;
}
.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label,
.mat-mdc-paginator .mat-mdc-select-value {
  color: #ffffff !important;
}
.mdc-text-field {
  color: #ffffff !important;
  border-color: #1736a9 !important;
}
.mdc-text-field--outlined .mdc-notched-outline {
  display: none;
}
.mat-mdc-text-field-wrapper {
  color: #ffffff !important;
  border: 1px solid #1736a9 !important;
  background: transparent !important;
}
.mdc-data-table__header-cell {
  border-color: #1736a9 !important;
}
.mdc-text-field {
  border-radius: 10px !important;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mdc-text-field:not(.mdc-text-field--disabled)
  .mdc-text-field__input::placeholder {
  color: #fff !important;
  font-size: 14px;
}
.mat-mdc-icon-button {
  color: #fff !important;
}
.mat-mdc-paginator-icon {
  fill: rgb(255 255 255 / 54%) !important;
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgb(255 255 255 / 12%) !important;
}
.mat-mdc-select-arrow svg {
  fill: #fff !important;
}
.mat-mdc-select-value-text {
  color: #fff;
}
::-webkit-scrollbar-thumb {
  background: #000747 !important;
  border: 0.2em solid #1736a9 !important;
}
::-webkit-scrollbar-track {
  background: #101861 !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
  opacity: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after,
.mdc-text-field--filled .mdc-line-ripple::after {
  display: none;
}
.table-responsive .mat-row,
.table-responsive .mat-header-row,
.mat-mdc-table,
.mat-mdc-table tbody,
.mat-mdc-table .mdc-data-table__row {
  background: #0b1674 !important;
}
.form-select {
    background-color: #000747 !important;
    border: 1px solid #1736a9 !important;
    color: #ffffff !important;
    box-shadow: none !important;
    font-size: 14px !important;
}
div.mat-mdc-select-panel {
    background-color: #0b1674 !important; 
}
.mat-mdc-option .mdc-list-item__primary-text {
    color: #ffffff !important;
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: rgb(0 0 0 / 32%) !important;
}
.mat-datepicker-content {    
    background-color: #0b1674 !important;
    color: rgb(255 255 255 / 87%) !important;
}
.mat-calendar-body-cell-content, .mat-date-range-input-separator {
    color: rgb(255 255 255 / 87%) !important;
}
.mat-calendar-table-header th {    
    background: #0b1674 !important;
}
.mat-mdc-button:not(:disabled) {
    color: #fff !important;
}
.mat-calendar-arrow {
    fill: rgb(255 255 255 / 54%) !important;
}
.header-nav .dropdown-menu {   
    background-color: #0b1674 !important;
}
.header-nav .dropdown-menu .dropdown-item {
    color: #eee;
}
.header-nav .dropdown-menu .dropdown-item:hover, 
.header-nav .dropdown-menu .dropdown-item:focus {
    background: rgba(0, 0, 0, 0.32) !important;
}
.mat-mdc-tab-label-container {    
    margin-bottom: 15px;
}
.mat-mdc-tab .mdc-tab__text-label {
    color: #eee !important;
}
.mat-mdc-tab.mdc-tab--active {
    background: #19319d !important;
}
table.cke_show_border td,
table.cke_show_border td p,
.cke_wysiwyg_div p {
  color: #000;
}
.sidebar-toggler::before {
  display: none;
}
.sidebar-toggler {
  pointer-events: none;
}
.modal-content{
  background: #000738;
}
.modal-header{
  color: #ffffff !important;
  border-color: #021c80;
}
.modal-body{
  color: #ffffff !important;
}
.modal-body label{
  margin-bottom: 10px;
}
.btn-primary{
  padding-left: 15px;
  padding-right: 15px;
}
.airdrop-form .mat-mdc-select{
  border: 1px solid #1736a9;
  border-radius: .25rem;
  padding: 5px 10px;
  width: 100% !important;
  max-width: 618px;
  background: #000747;
}
.mat-pseudo-checkbox-full{
  border-color: #45b1fc !important;
  border-width: 1px !important;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
  background: #45b1fc !important;
}
.p-multiselect{
  background: transparent !important;
  border: 1px solid #1736a9 !important;
}
.p-multiselect-panel .p-multiselect-header{
  background: #152a8e !important;
  border-bottom: 1px solid #14298b !important;
}
.p-multiselect-items-wrapper {
  background: #00094a !important;
}
.p-component, .p-component *{
  color: #ffffff !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
  background: #152a8e !important;
  border-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}
.p-inputtext {
  background: #00094a !important;
  border: 1px solid #00094a !important;
  box-shadow: none !important;
}
table thead th,
table tbody td {
  white-space: nowrap;
}
@media screen and (max-width: 575px) {
  .mat-mdc-paginator-range-actions {
    display: block !important;
  } 
  .mat-mdc-paginator-container {   
    justify-content: center !important;
    padding: 15px 8px 20px !important;
  }
  .mat-mdc-paginator-range-label {
    text-align: center;
  }
}